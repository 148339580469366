<template>
  <section>
    <div class="loader" v-if="isLoading">
        <div class="loader-content">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#59F87F"></stop><stop offset=".3" stop-color="#59F87F" stop-opacity=".9"></stop><stop offset=".6" stop-color="#59F87F" stop-opacity=".6"></stop><stop offset=".8" stop-color="#59F87F" stop-opacity=".3"></stop><stop offset="1" stop-color="#59F87F" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a12)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#59F87F" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>
    
          <span>Chargement des<br/>résultats...</span>
        </div>
    </div>
    <section class="results-btns">
      <div class="btn results-link" @click.once="directToResults">Passez directement au résultat</div>
      <pdfPrintButton />
    </section>
    <form class="emailForm">
      <h4 v-html="emailFormHeading"></h4>
      <label><input type="text" placeholder="Prénom & Nom" v-model="subscriber.name" :class="{error:validName === false}" /></label>
      <label><input type="mail" placeholder="E-mail *" v-model="subscriber.email" :class="{error:validEmail === false}" /></label>
      <div class="datasValidation"><input type="checkbox" required v-on:click="toggleChecked"/> <label>J'ai pris connaissance de <span class="dataLink" v-on:click="togglePolitique">l'utilisation qui sera faite des mes données</span> et j'en accepte les conditions</label></div>

      <PolitiqueConfidentialite v-if="showConditions" v-on:closePolitique="togglePolitique"/>

      <footer class="emailForm-footer">
          <div class="errorMsg" v-if="errorMsg.length > 0">
            <span v-for="errorm in errorMsg" :key = "errorm.index">
              {{ errorm }}<br/>
            </span>
          </div>
          <div id="submit" class="btn submit-btn" @click="onSubmit">{{ submitLabel }}</div>
          <span class="status" :style="{color: processing? 'orange' : 'green'}">{{ statusMsg }}</span>
          <span class="notice">* Votre E-mail sera uniquement utilisé pour vous transmettre vos résultats et vous informer de nos activités. Vous aurez la possibilité de vous désinscrire à tout moment.</span>

      </footer>
    </form>
  </section>
</template>

<script>
import PolitiqueConfidentialite from './politiqueConfidentialite.vue'
import pdfPrintButton from '@/components/pdfPrintButton.vue'
import axios from 'axios'

export default {
  name: 'emailForm',

  components: {
    PolitiqueConfidentialite,
    pdfPrintButton
  },

  props: {
    submitLabel: String,
    emailFormHeading: String,
    resultLinkLabel: String
  },

  data () {
    return {
      subscriber: {
        name: null,
        email: null,
        resultStr: [],
        results: [],
        isLoading: true
      },
      error: 'false',
      errorMsg: [],
      validName: false,
      validEmail: false,
      disableButton: false,
      statusMsg: '',
      processing: false,
      checked: false,
      showConditions: false
    }
  },

  methods: {
    togglePolitique: function () {
      this.showConditions = this.showConditions ? 0 : 1
    },

    toggleChecked: function () {
      this.checked = this.checked ? 0 : 1
      console.log(this.checked)
    },

    onSubmit: function () {
      // this.resultSlug()
      if (this.processing === true) {
        return
      }

      this.processing = true
      this.validData()
      if (this.errorMsg.length === 0) {
        console.log('pas erreur')
        this.statusMsg = 'Envoi en cours...'
        setTimeout(() => {
          this.statusMsg = 'envoyé'
          this.processing = false
          console.log('sending ? ' + this.errorMsg.length)
          this.sendResults()
        }, 1500)
      } else {
        this.processing = false
      }
    },

    testEmit: function () {
      console.log('EMit test ')
    },

    directToResults: function () {
      // this.resultSlug()
      this.subscriber.name = 'anonyme'
      this.subscriber.email = ''
      this.sendResults()
    },

    sendResults: function () {
      this.isLoading = true
      axios.post(
        this.$store.state.sourceUrl + '/wp-json/wp/v2/entree',
        {
          title: this.subscriber.name,
          status: 'publish',
          fields: {
            email: this.subscriber.email,
            // resultat: this.subscriber.resultStr
            resultat: this.$store.state.results
          }
        },
        {
          auth: {
            username: 'jerome',
            password: 'an7W 7Bq3 AR03 QTy9 KjXB i6hR'
          }
        }
      ).then(response => {
        console.log(response)
        this.isLoading = false
        this.$router.push('results')
      }, response => {
        console.log(response)
        this.error = true
      })
    },

    validData: function () {
      var reName = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/
      var reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      this.errorMsg = []
      this.validName = reName.test(this.subscriber.name)
      this.validEmail = reEmail.test(this.subscriber.email)

      if (!this.subscriber.name) {
        // this.errorMsg.push('Le champ nom et prénom est vide')
      } else if (this.validName === false) {
        this.errorMsg.push('Le champ nom et prénom contient des caratères interdits')
      }

      if (!this.subscriber.email) {
        this.errorMsg.push('Vous n\' avez pas renseigné d\'adresse email')
      } else if (this.validEmail === false) {
        this.errorMsg.push('Votre adresse email contient des erreurs')
      }

      if (!this.checked) {
        this.errorMsg.push('Vous devez acceptez les conditions')
      }
    }
  }
}

</script>

<style lang="scss">

  .loader {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 300;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Fredoka One', cursive;
    color: #59F87F;
    text-transform: uppercase;
    font-size: 50px;
    transition: opacity 1s ease-in;

    .loader-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        width: 150px;
      }
    }
  }

  .results-btns {
    display: flex;
    margin-top: 60px;
    margin-bottom: 40px;
    justify-content: space-between;

    @media all and (max-width: 960px) {
      flex-direction: column;
      align-items: flex-end;
    }

    .btn {
      font-size: 15px;
      text-align: center;
      line-height: 1.1;
      max-width: 140px;
      margin: 10px 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media all and (max-width: 960px) {
        margin-bottom: 5px;
      }
    }
  }

  .emailForm {
    max-width: 400px;
    margin-top: 0px;
    margin-left: auto;

    label {
      display: block;
      width: 100%;
    }

    input {
      width: 100%;
      border-radius: 0;
      border: 1px solid #000;
      padding: 10px 20px;
      font-size: 16px;
      color: #000;
      text-align: right;
      box-sizing: border-box;

      @media all and (max-width: 540px) {
        font-size: 18px;
      }

      &::placeholder {
        color: #000;
      }

      &:not(last-of-type) {
        margin-bottom: 10px;
      }
    }

    .datasValidation {
      text-align: left;
      display: flex;

      input {
        display: inline;
        float: left;
        width: auto;
        margin-bottom: 0;
        margin-top: 6px;
        margin-left: 0;
        margin-right: 6px;
      }
      .dataLink {
        text-decoration: underline;
        font-weight: bold;
         cursor: pointer;
      }
    }

    .emailForm-footer {
      margin-left: auto;
      // width: 270px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .submit-btn {
        font-size: 15px;
        background-color: #000;
        color: #fff;
        width: auto;
        font-weight: normal;
        margin-top: 20px;
        display: block;
        padding: 10px 25px;
        margin-bottom: 10px;

        &:hover {
        }

        &:disabled {
          opacity: 0.5;
        }
      }

      .results-link {
        color: #000;
        text-decoration: underline;
        cursor: pointer;
      }

      .errorMsg {
        margin-top: 20px;
        color: red;
        width: 100%;
      }

      .status {
        color: orange;
      }

      .notice {
        margin-top: 2em;
        font-size: 0.8em;
      }
    }
  }
</style>
